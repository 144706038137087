<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :options="PageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'apps-invoice-add'}"
          >
            Add Profiles
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @input="handleSearch"
            />
            <v-select
              v-model="TypeProfileValue"
              :options="TypeofProfile"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableProfiles"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      @sort-changed="onSortChange"
    >
      <!-- Column: AccountNumber -->
      <template #cell(account_number)="data">
        <b-link
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Patient -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              class="border"
              size="32"
              :class="data.item.profile_type.data[0] === 1 ? 'BAvatarPatientProfiles' : 'BAvatarPatientLeads' "
              :text="avatarText(`${data.value} ${data.item.lastname}`)"
              :to="{ name: 'kardex-profile', params: { idPatient: data.item.id } }"
            />
          </template>
          <span class="font-weight-bold text-nowrap">
            {{ `${data.value} ${data.item.lastname}` }}
          </span>
          <small class="text-muted">{{ data.item.profile_type.data[0] === 1 ? 'Profile' : 'Lead' }}</small>
        </b-media>
      </template>

      <!-- Column: DOB -->
      <template #cell(dob)="data">
        <span class="text-nowrap">
          {{ data.value | date-format }}
        </span>
      </template>

      <!-- Column: PhoneNumber -->
      <template #cell(phone)="data">
        <span class="text-nowrap">
          {{ data.value | phone-format }}
        </span>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BPagination, BTable, BLink, BMedia, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import profileService from '@core/services/profiles/profileService'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    vSelect,
    BTable,
    BLink,
    BMedia,
    BAvatar,
  },
  data() {
    return {
      fields: [{ key: 'account_number', label: '#', sortable: true }, { key: 'name', label: 'Patient Name', sortable: true }, { key: 'dob', sortable: true }, { key: 'phone', label: 'Phone Number', sortable: true }, { key: 'program', label: 'Coverage', sortable: true }, { key: 'name_source', label: 'Patient Source', sortable: true }, { key: 'enrollementType', sortable: true }],
      items: [],
      TypeProfileValue: '',
      TypeofProfile: ['Profiles', 'Leads'],
      type: '',
      perPage: 10,
      PageOptions: [10, 25, 50, 100],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      avatarText,
    }
  },
  watch: {
    perPage() {
      this.getProfiles()
    },
    TypeProfileValue(val) {
      if (val === 'Leads') {
        this.type = '0'
      } else if (val === 'Profiles') {
        this.type = '1'
      } else if (val === null) {
        this.type = ''
      }
      this.getProfiles()
    },
  },
  created() {
    this.getProfiles()
  },
  methods: {
    getProfiles() {
      profileService.getProfiles({
        page: this.actualpage,
        rows: this.perPage,
        mainSearch: this.searchTerm,
        phoneSearch: '',
        nameSearch: '',
        accountNumber: '',
        type: this.type,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
      }).then(response => {
        console.log(response)
        this.items = response.data.profiles
        this.FullCountEntries = response.data.profiles[0].full_count
        this.numPages = response.data.profiles[0].pages
        this.totalRows = this.FullCountEntries
        this.currentPage = this.actualpage
      })
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getProfiles()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getProfiles()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getProfiles()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
