import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class ProfileService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getProfiles(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getProfiles, ...args)
    }

    getProfileInfo(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getProfileInfo, ...args)
    }
}

function useProfileService() {
  const profileService = new ProfileService()

  return {
    profileService,
  }
}

const { profileService } = useProfileService()
export default profileService
